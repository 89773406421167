import React, { useState } from 'react';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div 
      className={`my-2 p-4 rounded-xl shadow-md transition-all mx-4 lg:mx-0
      ${isOpen ? 'bg-[#55285bd3]' : 'bg-[#93749680]'}`}>
      <h2 
       className="text-lg font-bold flex justify-between items-center cursor-pointer text-shadow text-white" 
       onClick={toggle}>
        {question}
        <span 
        className={`text-xl transition-all duration-300 ease-in-out transform hover:scale-125
         ${isOpen ? 'text-white' : 'text-white'}`}>
         {isOpen ? '−' : '+'}
        </span>
      </h2>
      <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0'} overflow-hidden`}>
        <div className="text-white text-shadow leading-relaxed" style={{ opacity: 0.93 }}>
          {answer}
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const answer1 = (
    <>
      <p>ZenithCats is a set of 8,888 hand-drawn NFTs by Axel Ramos/Athreas, </p>
      <p>each a gateway to the Web3,and the evolving digital realm.</p>
      <p>You're not just collecting art; by buying a Zenith Cat you become part of our exclusive
        community, and the very fabric of the internet itself.</p>
    </>
  );

  const answer2 = (
    <>
     <p>As a holder of a Zenith Cat NFT, you are entitled to several exclusive benefits, including:</p>
     <p><strong>- Whitelist:</strong></p>
      <p>You'll get early access and guarranteed spots in upcoming projects!</p>
     <p><strong>- Private Membership:</strong></p>
     <p> Private channels within its Discord server, where you can connect with other members and get the latest insider news and discussions.</p>
     <p><strong>- An purrrfect NFT companion! :3</strong></p>
    </>
  );

  const answer3 = (
    <>
      <p>You can stay updated through our social links in the webiste's explore page</p>
      <p>You can aslo click on the creator's profile to access his socials.</p>
      <p>Make sure to follow us!</p>
    </>
  );

  const answer4 = (
    <>
      <p>NFT privilages & benefits are given to the owner's address upon mint/purchase.</p>
      <p>To claim your exclusive membership, join our Discord server, and a bot wil handle it.</p>
      <p>Upon doing so you'll be given access to it. More detailed info within the server.</p>
    </>
  );

  const answer5 = (
    <>
     <p>We have a more detailed version in our Discord :3 This is a breif view of it:</p>
     <p><strong>- Q1:</strong></p>
      <p>//Dapp website Launch //Public Sale.</p>
     <p><strong>- Q2:</strong></p>
     <p>//Utility: Such as access to exclusive content, merchandise, or events.</p>
     <p><strong>- Q3</strong></p>
     <p> //New Collection ? </p>
     <p><strong>- Q4</strong></p>
     <p> //In Progress... </p>
    </>
  );

  const answer6 = (
    <>
      <p><strong>Short Introduction:</strong></p>
      <p>Upon discovering Web3 and DeFi, Axel's relentless spirit made this to be done. </p>
      <p>Learning day and night, applying lessons learned, failing, and NEVER giving up, a mastery was materialized and thus the ZenithCats where summoned, a symbol of grind and never giving up!</p>
      <p>You can learn more about how ZenithCats was created in the Features tab.</p>
      <p>Stay up to date with our socials for the latest info.</p>
    </>
  );

  return (
    <div className="bg-[url('https://bafybeigjcbasakbcaqullw43qe23etagg3oyjyyzumjstm2ugioecbhcy4.ipfs.nftstorage.link/closer%20galaxy%20car.png')] bg-no-repeat bg-cover">
      <div className="flex flex-col justify-center items-center mx-auto py-10">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-white text-5xl font-bold text-center text-shadow">
            FAQs
          </h1>
          <div className="w-full max-w-3xl mt-6 px-4 lg:px-0">
            <FaqItem
              question="What is Zenith Cats?"
              answer={answer1}
            />
            <FaqItem
              question="What benefits do I get from holding a Zenith Cat NFT?"
              answer={answer2}
            />
            <FaqItem
              question="ZenithCats & Creator updates?"
              answer={answer3}
            />
            <FaqItem
              question="Road Map?"
              answer={answer5}
            />
            <FaqItem
              question="How to claim NFT benefits?"
              answer={answer4}
            />
            <FaqItem
              question="How was ZenithCats created?"
              answer={answer6}
            />
          </div>
          <div>
          <h2 className="text-white text-xl font-bold text-center text-shadow">
            <p>Enjoy our community, stay fresh, and dont forget to drink water!</p>
            <p>✨🐱 📈</p>
          </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
