import React, { useState } from 'react';

const Termitem  = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div 
      className={`my-2 p-4 rounded-xl shadow-md transition-all mx-4 lg:mx-0
      ${isOpen ? 'bg-[#54325ad3]' : 'bg-[#93749680]'}`}>
      <h2 
       className="text-lg font-bold flex justify-between items-center cursor-pointer text-shadow text-white" 
       onClick={toggle}>
        {question}
        <span 
        className={`text-xl transition-all duration-300 ease-in-out transform hover:scale-125
         ${isOpen ? 'text-white' : 'text-white'}`}>
         {isOpen ? '−' : '+'}
        </span>
      </h2>
      <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'max-h-none' : 'max-h-0'} overflow-hidden`}>
        <div className="text-white text-shadow leading-relaxed" style={{ opacity: 0.93 }}>
          {answer}
        </div>
      </div>
    </div>
  );
};

const Terms = () => {

    const answer1 = (
        <>
          <p>
          The Zenith Cats website is intended for users who are at least eighteen (18) years old. Thus, by using the Zenith Cats website, you confirm that you are at least eighteen (18) years old and you are deemed to have read and accepted these terms exhaustively and unconditionally.</p>
          <p>If the user is under eighteen (18) years old, the user undertakes to obtain the prior and express consent of his/her parent or legal guardian to use the Zenith Cats website and to review the terms with them. The parent or legal guardian of a user under eighteen (18) years old undertakes to abide by these terms and acknowledges to be responsible for the activities of the user on the Zenith Cats website.</p>
          <p>In case of your disagreement, in whole or in part, with these terms, you must immediately cease any and all use of the Zenith Cats website.</p>
        </>
    );
      
      const answer2 = (
        <>
          <p>
          The terms may be updated from time to time as Zenith Cats may deem necessary or useful.</p>
          <p>In the event you do not agree to the terms, including as may be amended, you shall cease any and all utilization of the Zenith Cats website and related services.</p>
        </>
    );
      
      const answer3 = (
        <>
          <p>
          You are personally responsible for setting up the IT and telecommunications resources required to access the Zenith Cats website and use the services. You shall bear the cost of telecommunications when accessing the internet to use the Zenith Cats website.</p>
          <p>Zenith Cats reserves the right to, without notice nor compensation, temporarily or permanently, close the Zenith Cats website or access to one or more services in order to perform maintenance operations. Zenith Cats may carry out any changes and improvements to the Zenith Cats website and services that it deems necessary.</p>
        </>
    );      
  
  const answer4 = (
    <>
      <p><strong>4.1 GENERAL SERVICES</strong><br />
      The Zenith Cats website gives you access to functionalities, features, and services related to Zenith Cats NFT collections and community (the “Services”). Access to some services may only be available to the holder of a Zenith Cat NFT and require proof of ownership by connecting a supported blockchain-based digital wallet to the Zenith Cats website (“Digital Wallet”).</p>
      <p>Digital wallets are third-party products and services that are not affiliated, in any way whatsoever, with Zenith Cats nor in its custody or control. Access and use of digital wallets are at the user’s own risk and discretion and subject to their own applicable terms.</p>
      <p>You are solely responsible for your digital wallet security, including associated passwords, seed words, and keys. Zenith Cats shall not, in any event whatsoever, be held responsible for any risk associated with your access and/or use of a digital wallet. In the event you lose or can no longer access your digital wallet, Zenith Cats cannot retrieve your Zenith Cats NFT from your digital wallet.</p>
  
      <p><strong>4.2 NFT PRIMARY SALE SERVICES ON THE ZENITH CATS WEBSITE</strong><br />
      The Zenith Cats website may, in its sole discretion, give you access to primary sales of Zenith Cats NFTs through one or several of the following access rights: free mint for holders of a Zenith Cat NFT, allow list, and/or public sale.</p>
      <p>You will be required to connect your digital wallet to the Zenith Cats website in order to, depending on the rights you have been granted on the primary sale, (i) where applicable, confirm your current ownership of a Zenith Cats NFT, and/or (ii) where applicable, transfer to Zenith Cats the applicable purchase price, and (iii) receive an NFT.</p>
      <p>Applicable purchase price of a Zenith Cats NFT are set forth on the Zenith Cats website and are binding at the time of purchase. Additional fees (including gas fees) may be required at the time of purchase to complete the sale. Zenith Cats does not monitor nor benefit from these fees.</p>
      <p>Zenith Cats will directly transfer the Zenith Cats NFT you acquired to the supported digital wallet you connected to the Zenith Cats website, depending on the case and where applicable, upon confirmation of ownership of a Zenith Cats NFT or upon receipt of the corresponding purchase price.</p>
      <p>All sales completed on the Zenith Cats website are final. Pursuant to applicable law, you acknowledge that no withdrawal, return, and/or refund rights apply.</p>
  
      <p><strong>4.3 NFT SECONDARY SALE ON THIRD-PARTY PLATFORM</strong><br />
      You may resell and/or purchase Zenith Cats NFT on third-party platforms as part of secondary sales. Platforms dedicated to secondary sales are third-party services that are not affiliated with Zenith Cats and which access and use are at user’s own risk and discretion and subject to their own applicable terms.</p>
      <p>Zenith Cats may benefit from a percentage of the resale price of your Zenith Cats NFT, as set forth on the Zenith Cats website and subject to the third-party platform’s policy on which the resale is completed. You undertake to only resell and/or purchase Zenith Cats NFT on third-party platforms that (i) cryptographically verify each NFT owner’s rights to display and list a Zenith Cats NFT for sale, to ensure that only the actual owner of this NFT can proceed with its resale, and (ii) provide for a percentage of the resale price to be automatically granted to Zenith Cats upon resale.</p>
      <p>By reselling and/or purchasing a Zenith Cats NFT on an authorized third-party platform, you agree to abide by these terms.</p>
    </>
  );
  
  const answer5 = (
    <>
      <p><strong>5.1 USERS UNDERTAKINGS</strong><br />
      Users shall not, directly or indirectly, without being expressly authorized by these terms and/or the digital ownership assignment, or by prior written consent of Zenith Cats, in any way whatsoever:</p>
      <ul>
        <li>(I) Copy, modify, communicate, transmit or create any derivative work, use, commercialize or reproduce all or part of the Zenith Cats website and/or Zenith Cats property, and related intellectual property rights of Zenith Cats, or register or attempt to register any trademark or copyright or otherwise acquire additional intellectual property rights in or to any Zenith Cats property; use the Zenith Cats website or any Zenith Cats property to create, endorse, support, promote or condone any content, material or speech that is, or may be deemed to be by Zenith Cats at its sole discretion, defamatory, obscene, pornographic, indecent, abusive, offensive, harassing, violent, hateful, racist, discriminatory, inflammatory or otherwise objectionable or inappropriate; and, in general, access, download, upload, share, communicate any content and/or use the Zenith Cats website or Zenith Cats property in a way that may infringe the rights, including intellectual property rights, of Zenith Cats or third parties, damage the reputation, rights and interests of Zenith Cats, or falsely suggest an affiliation with or endorsement by Zenith Cats.</li>
        <li>(II) Decompile, disassemble the Zenith Cats website, reverse engineer or attempt to discover or reconstruct the source code, the ideas on which it is based, the algorithms, the file formats or the programming or interoperability interfaces of the Zenith Cats website except to the extent permitted by law, in any manner whatsoever. In the event the user wishes to obtain the information necessary to implement the interoperability of Zenith Cats the website with other software developed or independently acquired by the user, for use in accordance with the destination of the Zenith Cats website, the user undertakes, before relying on a third party, to first consult Zenith Cats which may provide the necessary information for the implementation of such interoperability. The user acknowledges that, where appropriate, the exact cost incurred internally by Zenith Cats for providing this information will be invoiced by Zenith Cats to the user.</li>
        <li>(III) Proceed alone, or with help of a third-party service provider, to correct of any errors in and/or alter any content of the Zenith Cats website in order to make it conform to her/his own purposes (Zenith Cats alone reserves for itself the exercise of this right in accordance with exceptions provided by law); or integrate or combine the Zenith Cats website with other software or documents or create composite or derivative works with help of all or parts of the Zenith Cats website; and, in general, violate any law or regulation, or make any use of the Zenith Cats website, services or Zenith Cats property that may be deemed contrary to public order or unfair.</li>
      </ul>
      <p><strong>5.2 UNDERTAKINGS OF ZENITH CATS</strong><br />
      Zenith Cats endeavors use reasonable efforts to (I) act honestly, fairly and professionally, (II) communicate with the holders of Zenith Cats NFT in a fair and clear manner, (III) prevent, identify, manage and disclose any conflicts of interest that may arise, (IV) maintain all of its systems and security access protocols to appropriate standards of applicable laws and regulations, and, in general (V) act in the best interests of the holders of Zenith Cats NFT and treat them equally, unless provided otherwise on the Zenith Cats website or any other notice by Zenith Cats.</p>
    </>
  );
  
  const answer6 = (
    <>
      <p><strong>6.1 ZENITH CATS WEBSITE SECURITY</strong><br />
      Zenith Cats shall make its best efforts to deploy the necessary resources to ensure users access to and effective operation of the Zenith Cats website twenty-four hours a day and seven days a week. However, given the limitations related to the internet, Zenith Cats cannot guarantee that access and operation of the Zenith Cats website will not be interrupted, such as in event of force majeure, malfunction of the users equipment, malfunctions of the users internet networks and/or maintenance operations designed to improve the Zenith Cats website.</p>
      <p>The user hereby represents, warrants, and agrees that, when accessing and/or using the Zenith Cats website and services, the user shall refrain from any act and/or omission which could, in any way whatsoever, jeopardize the security of the Zenith Cats website; (II) attempt, in any manner, to obtain passwords or other security information from any other user; (III) violate the security of any computer network, or cracks any passwords or security encryption codes; (IV) run any processes that interfere with the proper working of the Zenith Cats website and services; (V) disturb, hinder and/or prevent the proper functioning of the Zenith Cats website and services (such as, but not limited to, downloading viruses or malicious code whatsoever or by performing hacking activities of any nature or that otherwise interfere with the proper functioning of the Zenith Cats website and services).</p>
  
      <p><strong>6.2 MALICIOUS THIRD PARTIES</strong><br />
      Please be aware that malicious third parties may offer for sale NFT counterfeited Zenith Cats property and/or falsely act as affiliated to or endorsed by Zenith Cats. In case of doubt, please contact Zenith Cats in accordance with Article 12 below.</p>
      <p>Your interactions with these third parties are at your own risks and discretion. Zenith Cats is not responsible, in any way whatsoever, for any act or omission of said malicious third parties, nor any adverse consequence resulting from your interactions with them.</p>
    </>
  );
  
  const answer7 = (
    <>
      <p>
      Zenith Cats offers services related to crypto-assets (NFT) that are unique and not fungible with other crypto-assets. Each Zenith Cats NFT is unique and exclusive to its owner.</p>
      <p>Zenith Cats is not a provider of digital asset services including, but not limited to, services regarding initial coin offering; custody of digital assets on behalf of third parties or access to digital assets for the purpose of holding, storing and transferring digital assets; the purchase and sale of digital assets in legal tender; the exchange of digital assets for other digital assets; the operation of a digital asset trading platform; or any services related to third party order reception and transmission, third party portfolio management, advice, underwriting, guaranteed placement and unguaranteed placement.</p>
      <p>Zenith Cats does not provide asset intermediation services including, but not limited to, services related to the offer, to clients or potential clients, to acquire rights on one or more assets on the basis of the possibility of a direct or indirect financial return or similar economic effect.</p>
      <p>In this regard, the user hereby represents, warrants, and agrees that any use of the Zenith Cats website and services, including any purchase of a Zenith Cats NFT, is made for its sole personal collection, use and enjoyment. User shall refrain from any act and/or omission which could, in any way whatsoever, be interpreted as or suggest that (I) the user is accessing or using the Zenith Cats website and/or services, including purchasing a Zenith Cats NFT, for speculative or investment purposes, for use as a substitute for currency or other medium of exchange, or for resale or redistribution, or that (II) accessing or using the Zenith Cats website and/or services, including purchasing a Zenith Cats NFT, may constitute an opportunity to gain economic benefit or profit, or an investment, equity, or other ownership or profit-sharing interest in Zenith Cats or its affiliates or business partners of any kind. In this regard, Zenith Cats makes no warranties whatsoever with regard to any future value or resale price of a Zenith Cats NFT.</p>
      <p>You are the sole responsible for your compliance with applicable laws and regulations, including tax obligations in relation with NFT and cryptocurrencies. Please be aware that changes in applicable laws and regulations may impact the services and your rights and obligations regarding your Zenith Cats NFT.</p>
    </>
  );
  
  const answer8 = (
    <>
      <p><strong>8.1 WARRANTIES</strong><br />
      It is expressly agreed that Zenith Cats obligations under these terms are obligations of means. To the greatest extent permitted by applicable law, the user expressly acknowledges that the Zenith Cats website, services, including Zenith Cats NFT, are provided by Zenith Cats “as is”. Other than as provided in these terms and to the fullest extent permissible by applicable law, Zenith Cats makes no other warranties or representations, express or implied, and hereby disclaims all implied warranties.</p>
      <p>Zenith Cats makes no warranties whatsoever, regarding the Zenith Cats website, the services, and any transaction involving, in whole or in part, Zenith Cats NFT and/or cryptocurrencies, including, but not limited to, with regard to any risk associated with Zenith Cats NFT, such as, risk of failure to transfer the NFT to the user due to an error, such as forgotten passwords, mistyped addresses or incorrectly constructed transactions, mining attacks, cybersecurity attacks, blockchain malfunctions or other technical errors, telecommunications failure, unfavorable regulatory determinations or actions in one or more jurisdictions (including with respect to NFTs and/or cryptocurrencies), taxation of NFTs or cryptocurrencies, personal information disclosure, uninsured losses, unanticipated risks, volatility risks, server failure or data loss, corrupted or otherwise inaccessible digital wallets, unauthorized access, inability to access, transfer or display the NFT, risks arising from third-party providers, including third-party providers that may mint and/or store the NFT, and any damages arising from any unauthorized third-party activities, including without limitation the introduction of viruses or other malicious code, phishing attacks, Sybil attacks, fifty-one percent (51%) attacks, brute forcing changes to the protocol rules of the blockchain (i.e., “forks”), or other means of attack that may affect, in any way, the NFT.</p>
      <p>Zenith Cats does not warrant that the user’s access and use of the Zenith Cats website and the services will be uninterrupted, timely, and free from errors, malfunctions, viruses, and malicious codes of any kind whatsoever. No information provided on the Zenith Cats website is ever guaranteed in any way whatsoever.</p>
      <p>The user expressly acknowledges and accepts that electronic communications may not be free from interferences with third parties. Zenith Cats shall in no event be liable for any damages, losses, costs, expenses, and loss of profit, of whatever nature and kind, resulting from or arising out of such interference with third parties through the internet network.</p>
  
      <p><strong>8.2 INDEMNIFICATION</strong><br />
      The user shall indemnify, defend, and hold Zenith Cats, and its member, directors, officers, employees, and agents (collectively, "the Zenith Cats claimants") harmless from and against any and all claims, liabilities, judgments, awards, losses, damages, costs, and expenses (including, but not limited to, reasonable attorneys' fees), arising out from (I) any breach by the user, or any of its respective employees or agents if any, of this terms or of any warranty, representation, covenant, or obligation contained herein; (II) any infringement or alleged infringement on the part of the Zenith Cats claimants in connection with any and all use of the Zenith Cats website, services, and/or Zenith Cats NFT by the user.</p>
  
      <p><strong>8.3 LIMITATION OF LIABILITY</strong><br />
      To the greatest extent permitted by applicable law, in no event shall Zenith Cats be liable for any indirect damages caused to a user in relation to access and/or use of the Zenith Cats website and/or the services.</p>
    </>
  );
  
  const answer9 = (
    <>
      <p><strong>9.1 BREACH OF TERMS</strong><br />
      If the user's utilization of the Zenith Cats website is deemed in breach of the terms, Zenith Cats reserves the right to limit, suspend, or terminate, temporarily or permanently, as of right, without any indemnification, compensation, or refund whatsoever being owed and without any prejudice to any legal action that Zenith Cats may be entitled to, the user’s access to the Zenith Cats website and/or the services available to the user. Zenith Cats shall inform the user of its decision to suspend or limit the user’s rights and may, at its discretion, decide to restore the user’s rights.</p>
  
      <p><strong>9.2 THIRD PARTY CONTENT</strong><br />
      The Zenith Cats website may display hypertext links redirecting the user to third-party websites and/or applications. Zenith Cats shall not be held liable, in any way whatsoever, in respect of any hypertext links to third parties that may be accessible on the Zenith Cats website. In this respect, please note that Zenith Cats neither has any control over the content published by such third parties nor monitors such content. As a result, Zenith Cats shall in no event be held liable in respect of any content published by third parties whatsoever. The user is hereby invited to duly read the terms and conditions governing third-party websites and applications that the user may access by clicking on hypertext links displayed on the Zenith Cats website and related website pages.</p>
  
      <p><strong>9.3 ASSIGNMENT</strong><br />
      Zenith Cats may transfer all or part of its rights and obligations under these terms to any entity, affiliate, subsidiary, or third-party, including, but not limited to, in case of merger, division, partial asset transfer, or full or partial business disposal. The user shall not transfer all or part of its obligations under these terms, in any way whatsoever, without the express prior written agreement of Zenith Cats.</p>
  
      <p><strong>9.4 VALIDITY</strong><br />
      In the event any one or more of the provisions of these terms are unenforceable, it shall be stricken from the applicable document, but the remainder of the terms shall remain in force. The provisions declared unenforceable shall be replaced by the provisions that are closest in meaning and scope to the initial provisions.</p>
  
      <p><strong>9.5 ELECTRONIC COMMUNICATION</strong><br />
      In accordance with applicable law, the user acknowledges and agrees that information exchanged between the user and Zenith Cats in electronic format (including via email) shall be deemed valid means of communication, notice, and proof, equivalent to information in paper format.</p>
    </>
  );
  
  const answer10 = (
    <>
      <p>
      The validity of these terms and any of its terms and provisions, as well as the rights and duties of the parties hereunder, shall be governed, interpreted, and enforced in accordance with the federal law of the United States and the law of the State of Delaware.</p>
      <p>In the event of a dispute arising between the parties regarding the interpretation, execution, or termination of these terms, the parties shall endeavor to resolve such issue through amicable means. Notwithstanding anything to the contrary provided herein, the state or federal court of appropriate jurisdiction located in Kent County, Delaware shall be the venue for any formal disputes between user and Zenith Cats.</p>
    </>
  );

  const answer11 = (
    <>
      <p>
        PLEASE CONTACT US BY E-MAIL AT [EMAIL PROTECTED] OR BY MAIL USING THE DETAILS PROVIDED BELOW:

        [Undisclosed Address]
      </p>
    </>
  )

  const answer12 = (
    <>
      <p>
      Notice. We respect the intellectual property rights of others and we ask you to do the same. If you are a copyright owner or an agent of a copyright owner and believe that any content on the services infringes upon your copyrights (other than content that was previously uploaded by you to Zenith Cats and as to which the user-to-user complaint process applies as described below), you may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by contacting our copyright agent at [email protected] or to the address provided in Article 12, attention DMCA Agent. If a copyright owner is under the age of 13, a DMCA notice must be submitted by a parent or other representative of the rightsholder, rather than directly by an under-13 user. You acknowledge that if you fail to comply with all of the requirements of this section, your DMCA notice may not be valid. You must provide the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
      <ul>
        <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other right being infringed;</li>
        <li>A description of the copyright-protected work or other intellectual property right that you claim has been infringed;</li>
        <li>A description of the material that you claim is infringing and where it is located;</li>
        <li>Your address, telephone number, and email address;</li>
        <li>A statement by you that you have a good faith belief that the use of those materials is not authorized by the copyright owner, its agent, or the law; and</li>
        <li>A statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
      </ul>
      <p>Counter-Notice. Regarding any content that was removed or disabled, if you believe that your content is not infringing or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your content, you may send a counter-notice to our copyright agent. Any counter-notice submitted on behalf of an under-13 user must be submitted by a parent or other adult representative. When our copyright agent receives a counter-notice, we may send a copy of the counter-notice to the original complaining party informing that party that we may, in 10 business days, replace the removed content or stop disabling it. Unless the copyright owner files an action seeking a court order against the provider of the content, the removed content may be replaced or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, in Zenith Cats’ sole discretion.</p>
      <p>Repeat Infringer Policy. Zenith Cats’ intellectual property policy is to: (I) remove or disable access to material that Zenith Cats knows to be infringing the intellectual property rights of third parties or that has been identified in a valid DMCA notice submitted by an intellectual property rights owner or his or her agent; and (II) in appropriate circumstances, to terminate the account of and block access to the Zenith Cats website and services by any user who repeatedly or egregiously infringes other people’s copyrights or other intellectual property rights.</p>
      <p>Trademark Infringement Our policies prohibit a user from providing user-generated content that infringes trademarks. If a user provides content that infringes trademarks, the user’s content can be blocked or removed.</p>
      <p>If you are a trademark owner that believes your trademark is being infringed, please note that we are not in a position to mediate disputes between users and the holders of trademark rights. That being said, we take your rights seriously. So, we will look into and try to resolve any allegations of trademark infringement. Therefore, if you feel that your trademark rights are being infringed, contact our trademark agent at [email protected] or at the address provided in Article 12, attention Trademark Agent. When you contact us, please provide the following information in writing:</p>
      <ul>
        <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the trademark;</li>
        <li>A description of the trademark right that you claim has been infringed;</li>
        <li>A description of the material that you claim is infringing and where it is located;</li>
        <li>Your address, telephone number, and email address;</li>
        <li>A statement by you that you have a good faith belief that the use of those materials is not authorized by the trademark owner, its agent, or the law; and</li>
        <li>A statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the trademark owner or authorized to act on the copyright or intellectual property owner’s behalf.</li>
      </ul>
    </>
  );

  return (
    <div className="bg-[url('https://bafybeih4ysiz73vc776oeliax2ck2z3r7nfttewvvlzh4sx6ratu3y2a4m.ipfs.nftstorage.link/carcourt.png')] bg-no-repeat bg-cover">
      <div className="flex flex-col justify-center items-center mx-auto py-10">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-white text-5xl font-bold text-center text-shadow">
            Terms of use 
          </h1>
          <p className="text-white w-5/6 mx-auto text-center font-semibold text-sm mt-3 text-shadow">
          THESE TERMS AND CONDITIONS (“TERMS”) RULE THE RELATIONSHIP BETWEEN PUDGY PENGUINS AND ANY PERSON (“USER”, “YOU”) ACCESSING AND/OR USING ZENITH CATS WEBSITE (WWW.ZENITHCATS.COM, ALSO REFERRED TO HEREIN AS “ZENITH CATS WEBSITE”). PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE ZENITH CATS WEBSITE.
         </p>
          <div className="w-full max-w-3xl mt-6 px-4 lg:px-0">
            <Termitem
              question="1. CONDITIONS OF USE OF THE ZENITH CATS WEBSITE"
              answer={answer1}
            />
            <Termitem
              question="2. AMENDMENT TO THE TERMS"
              answer={answer2}
            />
            <Termitem
              question="3. ACCESS TO THE ZENITH CATS WEBSITE"
              answer={answer3}
            />
            <Termitem
              question="4. SERVICES"
              answer={answer4}
            />
            <Termitem
              question="5. UNDERTAKINGS OF THE PARTIES"
              answer={answer5}
            />
            <Termitem
              question="6. SECURITY"
              answer={answer6}
            />
            <Termitem
              question="7. DISCLAIMER"
              answer={answer7}
            />
            <Termitem
              question="8. WARRANTY AND LIABILITY"
              answer={answer8}
            />
            <Termitem
              question="9. MISCELLANEOUS"
              answer={answer9}
            />
            <Termitem
              question="10. APPLICABLE LAW"
              answer={answer10}
            />
            <Termitem
              question="11. CONTACT INFORMATION"
              answer={answer11}
            />
            <Termitem
              question="12. DIGITAL MILLENNIUM COPYRIGHT ACT"
              answer={answer12}
            />
          </div>
          <div>
          <h2 className="text-white text-xl font-bold text-center text-shadow">
            <p>Enjoy our community, stay fresh, and dont forget to drink water!</p>
            <p>✨🐱 📈</p>
          </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
