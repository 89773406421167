import abi from './abis/src/contracts/ZenithCats.sol/ZenithCats.json'
import address from './abis/contractAddress.json'
import { getGlobalState, setGlobalState } from './store'
import { ethers } from 'ethers'

const { ethereum } = window
const contractAddress = address.address
const contractAbi = abi.abi
const opensea_uri = `https://opensea.io/assets/ethereum/${contractAddress}/`
const ALCHEMY_URL = 'https://eth-mainnet.g.alchemy.com/v2/rUOycMj9cbQrTYgXNahDgbeGHalC_m6k';

const getEtheriumContract = () => {
    const connectedAccount = getGlobalState('connectedAccount');
    let provider;
    let contract;

        provider = new ethers.providers.JsonRpcProvider(ALCHEMY_URL);
        contract = new ethers.Contract(contractAddress, contractAbi, provider);


    return contract;
};


const loadNfts = async () => {
    try {
        const contract = getEtheriumContract();
        const nfts = await contract.getAllNFTs();
        console.log("NFTs loaded:", nfts); 
        const structuredData = structuredNfts(nfts);
        console.log("Structured NFTs:", structuredData); 
        setGlobalState('nfts', structuredData);
    } catch (error) {
        reportError(error);
    }
};

const structuredNfts = (nfts) =>
 nfts
.map((nft) => ({
    id: Number(nft.id),
    url: opensea_uri + nft.id,
    buyer: nft.buyer,
    imageURL: nft.imageURL,
    cost: parseInt(nft.cost._hex) / 10 ** 18,
    timestamp: new Date(nft.timestamp.toNumber()).getTime(),
}))
.reverse()

const reportError = (error) => {
    console.error(error);
    setGlobalState('error', error.message);
};

    
export {
    loadNfts
}
