import ethlogo from '../assets/ethlogo.png'
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <div className="w-full flex md:justify-center justify-between
    items-center flex-col p-4 gradient-bg-footer">
        <div className="w-full flex flex-col justify-between items-center my-4">
            <div className="flex flex-1 justify-evenly items-center flex-wrap
            sm:mt-0 mt-5 w-full text-white text-base text-center">
                <div className="mx-4 cursor-pointer"><Link to="/">Explore</Link></div>
                <div className="mx-4 cursor-pointer"><Link to="/terms">Terms of use</Link></div>
                <div className="mx-4 cursor-pointer"><Link to="/faq">FAQ</Link></div>
            </div>

            <div className="flex justify-center items-center mt-2">
                <img className='w-5' src={ethlogo} alt="Adulam logo" />
                <span className='text-white text-sm py-3'>
                    ZenithCats (c) 2024 ⚡Our adventure awaits!⚡</span>
            </div>
        </div>
    </div>
  )

}

export default Footer
