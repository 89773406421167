import React, { useState } from 'react';

const Featitem  = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div 
      className={`my-2 p-4 rounded-xl shadow-md transition-all mx-4 lg:mx-0
      ${isOpen ? 'bg-[#54325ad3]' : 'bg-[#93749680]'}`}>
      <h2 
       className="text-lg font-bold flex justify-between items-center cursor-pointer text-shadow text-white" 
       onClick={toggle}>
        {question}
        <span 
        className={`text-xl transition-all duration-300 ease-in-out transform hover:scale-125
         ${isOpen ? 'text-white' : 'text-white'}`}>
         {isOpen ? '−' : '+'}
        </span>
      </h2>
      <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'max-h-none' : 'max-h-0'} overflow-hidden`}>
        <div className="text-white text-shadow leading-relaxed" style={{ opacity: 0.93 }}>
          {answer}
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  const answer1 = (
    <>
    <h2><strong>The Mind 🧠</strong></h2>
      <p>Axel embarked on an extreme self-growth challenge, diving deep into the realms of Web3 and DeFi. With a relentless spirit and an unwavering commitment to growth, Axel's journey began.</p>
      <p>Day and night, Axel dedicated himself to learning and applying new knowledge. Despite facing failures and encountering numerous obstacles, his faith & determination never wavered.</p>
      <h2><strong>The Body 💪</strong></h2>
      <p>It started at the beggining of the year 2024 where Axel decided to confront a extreme self-growth challenge facing not only knowledege but physical endurance aswell. Focused only on the bare reality of what is the present moment, it began.</p>
      <h2><strong>The Spirit 🌀</strong></h2>
      <p>Imagination was cut off and accion was prioritized, begining with self mastery and applied knowledege, with the help of God and sheer willpower, what seamed to be years of learning and applying it. Despiste failing, struggle, and encountering error codes... It was achieved in a matter on weeks by placing faith in God and never giving up despiste adversity. 
        Thus culltivating a relentless spirit along the jounery which is engraved in the dna of every signle ZenithCat</p>
    </>
  );


  const answer2 = (
    <>
      <h2 className='text-center py-1 h-9'><strong>Handmade NFTs.</strong></h2>
      <p className='text-center'>Did you know all of the NFT layers where handedrawn by Axel? and mixed by a code he made?</p>
      <h2 className='text-center py-3 h-11'><strong>Galaxy Background.</strong></h2>
      <p className='text-center'>Did you know the galaxy background is actually a representation of the night sky constellations? An approximate view right when Axel was born...</p>
      <img className="mx-auto rounded-xl" src="https://bafybeigwygq2zrn62tazrsaua7ycvnk464hszgtgtejfyya7lsztvoxose.ipfs.nftstorage.link/Galaxy%233.png" alt="Galaxy background representing constellations at Axel's birth" style={{ width: "auto", height: "300px" }} />
      <p className='text-center'>The constellations in sight are Leo and Cancer.</p>
      <img className="mx-auto rounded-xl" src="https://bafybeiec4i4u3sb7vtl63by56nru7lh6kkktyuigiehuitobd4fzzre4si.ipfs.nftstorage.link/Galaxy%233.png" alt="Constellations Leo and Cancer" style={{ width: "auto", height: "300px" }} />
    </>
  );
  
  
  return (
    <div className="bg-[url('https://bafybeib4otojqaqpc6p6py5p6vpraffmm2b3f6pxikqqm7xeaqggshpfmy.ipfs.nftstorage.link/Center%20kitty.png')] bg-no-repeat bg-cover">
      <div className="flex flex-col justify-center items-center mx-auto py-10">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-white text-5xl font-bold text-center text-shadow">
            Features
          </h1>
          <div className="w-full max-w-3xl mt-6 px-4 lg:px-0">
            <Featitem
              question="The story behind ZenithCats?"
              answer={answer1}
            />
            <Featitem
              question="Did you know?"
              answer={answer2}
            />
          </div>
          
          <div>
            <h1 className="text-white text-3xl font-bold text-center text-shadow py-10">
            🔐 Private Memership ⛓
            </h1>
          </div>
          <div  className="my-2 p-4 rounded-xl shadow-md transition-all mx-4 lg:mx-0 bg-[#93749680]">
          <h2 class="text-center text-lg font-bold flex items-center cursor-pointer text-shadow text-white">
    Each ZTC/ZenithCats Holder will be granted exclusive access to our Discord Server!
          </h2>
          </div>
          <div>
          <h1 className="text-white text-3xl font-bold text-center text-shadow py-10">
            More NFT features soon...
          </h1>
          <h2 className="text-white text-xl font-bold text-center text-shadow">
            <p>Enjoy our community, stay fresh, and dont forget to drink water!</p>
            <p>✨🐱 📈</p>
          </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
