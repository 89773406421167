import { useState } from 'react';
import ethlogo from '../assets/ethlogo.png';
import { useGlobalState } from '../store';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Header = () => {
    const [connectedAccount] = useGlobalState('connectedAccount');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="w-full flex flex-wrap justify-between items-center p-4 mx-auto relative pl-3 ">
            <button onClick={handleMenuToggle} className="md:hidden z-30">
                <svg className={`w-6 h-6 text-white ${isMenuOpen ? 'hidden' : 'block'}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
                <svg className={`w-6 h-6 text-white ${isMenuOpen ? 'block' : 'hidden'}`} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>

            <div className="flex-1 flex  justify-start md:justify-start items-center pl-1">
                <img className="w-8 mr-2" src={ethlogo} alt="ZenithCats Logo"/>
                <span className="text-white text-2xl fugaz-one ">ZenithCats</span>
            </div>

            <div className={`fixed inset-0 w-full h-full bg-black bg-opacity-75 z-10 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:hidden transition-transform duration-300 ease-in-out`}>
                <ul className='text-white list-none flex flex-col items-center justify-center h-full'>
                    <li className="my-4 cursor-pointer"><Link to="/" onClick={() => setIsMenuOpen(false)}>Explore</Link></li>
                    <li className="my-4 cursor-pointer"><Link to="/features" onClick={() => setIsMenuOpen(false)}>Features</Link></li>
                    <li className="my-4 cursor-pointer"><Link to="/faq" onClick={() => setIsMenuOpen(false)}>FAQ</Link></li>
                </ul>
            </div>

            <ul className='text-white md:flex hidden  flex-row justify-between items-center flex-initial'>
                <li className="mx-4 cursor-pointer"><Link to="/">Explore</Link></li>
                <li className="mx-4 cursor-pointer"><Link to="/features">Features</Link></li>
                <li className="mx-4 cursor-pointer"><Link to="/faq">FAQ</Link></li>
            </ul>
            
            <div className='pl-9'>
            <ConnectButton accountStatus="address" chainStatus="none"/>
            </div>
           
        </nav>
    );
}

export default Header;