import avatar from '../assets/owner.jpg'
import twitter from '../assets/twitter_icon.png'
import instagram from '../assets/instagram_icon.png'
import discord from '../assets/discord_icon.png'
import { setAlert, setGlobalState, useGlobalState} from '../store'
import { useAccount } from 'wagmi'
import { useEffect, useState } from "react";
import { useEthersSigner } from '../utils/ethersHelper';
import { 
    getContractInstance, 
    payToMint 
  } from '../utils/interact';

const Hero = () => {
    const { isConnected } = useAccount();
    const [nfts] = useGlobalState('nfts')
    const onMintNFT = async () => {
        setGlobalState('loading', {
            show: true,
            msg: 'Minting new NFT to your account'
        })

        await payToMint()
        .then(() => setAlert('Minting Successful...', 'green'))
        .catch(() => setGlobalState('loading', {show: false, msg: ''}))
    }

  const account = useAccount();
  const signer = useEthersSigner();

  const [contractInstance, setContractInstance] = useState("");

  const getContract = async () => {
    if (account.isConnected && account.address) {
      const theSigner = await signer;
      const ZenithCatsContract = await getContractInstance(theSigner);
      setContractInstance(ZenithCatsContract);
    }
  };
  
  useEffect(() => {
    getContract();
  }, [account.address, signer]);

  const mintNft = async () => {
    const theSigner = await signer;
    const { success, msg } =  await payToMint(contractInstance, theSigner);
    alert(msg);
  }

    
    return (
        <div
        className="bg-[url('https://bafybeigri4bopxwxbb76haq6lxgu45pwaxqsj63cl25bq7dd544owk6evq.ipfs.nftstorage.link/galaxy%20car.png')] bg-no-repeat bg-cover">
        <div
        className="flex flex-col justify-center items-center mx-auto
        py-10">
        <div className="flex flex-col justify-center items-center">
        <h1 className="text-white text-5xl font-bold text-center">
        Welcome to the <br />
        <span className="text-gradient">ZenithCats NFTs</span> Collection
        </h1>
        <p className="text-white font-semibold text-sm mt-3 text-shadow">
        Mint, adpot, and collect the newest NFTs around.
        </p>
        {isConnected && (
            <button style={{ marginTop: 24 }} className='shadow-xl shadow-black text-white
            bg-[#dd29e3] hover:bg-[#bd25ae] p-2
            rounded-full cursor-pointer my-4'
            onClick={()=> mintNft()}>
                Mint Now
            </button>
        )}
        <a
        href="https://linktr.ee/athreas"
        className="flex flex-row justify-center space-x-2 items-center
        bg-[#000000ad] rounded-full my-4 pr-3 cursor-pointer"
        >
        <img
        className="w-11 h-11 object-contain rounded-full"
        src={avatar}
        alt="Adulam Logo"
        />
        <div className="flex flex-col font-semibold">
        <span className="text-white text-sm">0xf39...2266</span>
        <span className="text-[#dd29e3] text-xs">Athreas</span>
        </div>
        </a>
        <p className="text-white text-sm font-medium text-center text-shadow">
        Join Axel Ramos, where relentless spirit shapes triumph.<br />
        Mint your Zenith Cat and charge into the unprecedented territories of the web3. <br /> 
        Seize the thrill—adopt a legend. 
        </p>
        <ul
         className="flex flex-row justify-center space-x-2 items-center my-4">
            <a
             className="bg-white hover:scale-50 transition-all
             duration-75 delay-75 rounded-full mx-2"
             href="https://twitter.com/zenithcats?s=11&t=Sxw0oP5DoCTYCLdSysS7SA"
             >
                <img className="w-7 h-7" src={twitter} alt="twitter" />
            </a>
            <a
             className="bg-white hover:scale-50 transition-all
             duration-75 delay-75 rounded-full mx-2"
             href="https://www.instagram.com/zenithcats?igsh=OHl1M292ZTFybXQ0"
             >
                <img className="w-7 h-7" src={instagram} alt="instagram" />
            </a>
            <a
             className="bg-white hover:scale-50 transition-all
             duration-75 delay-75 rounded-full mx-2"
             href="https://discord.gg/WtwGJq9sS3"
             >
                <img className="w-7 h-7" src={discord} alt="discord" />
            </a>
        </ul>

        <a
        className="flex flex-row justify-center space-x-2 items-center
        bg-[#000000ad] rounded-full  pr-1 pl-1 cursor-pointer"
        >

        <div className="flex flex-col font-bold text-shadow">
        <span className="text-white text-sm l-4 ">ETH Contract Address:</span>
        </div>
        </a>

        <a
        href="https://etherscan.io/address/0xdc2768f656d518f0cdfb27f06d7613c9772b847f"
        className="flex flex-row justify-center space-x-2 items-center
        bg-[#000000ad] rounded-full my-4 pr-1 pl-1 cursor-pointer hover:bg-[#a41e97] transition-all
        duration-75 delay-100"
        >

        <div className="flex flex-col font-semibold">
        <span className="text-white text-sm l-4 ">0xdc2768f656d518f0cdfb27f06d7613c9772b847f</span>
        </div>
        </a>

        <div
        className="shadow-xl shadow-black flex flex-row
        justify-center items-center w-10 h-10 rounded-full
        bg-white cursor-pointer p-3 ml-4 text-black
        hover:bg-[#bd25ae] hover:text-white transition-all
        duration-75 delay-100"
        >
        <span className="text-xs font-bold">{nfts.length}/8888</span>
        </div>
        </div>
        </div>
        </div>
        )
 }


export default Hero