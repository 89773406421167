import { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { loadNfts } from './Zenith'
import Alert from './components/Alerts'
import Artworks from './components/Artworks'
import Footer from './components/Footer'
import Header from './components/Header'
import Hero from './components/Hero'
import Loading from './components/Loading'
import { useGlobalState } from './store'
import FAQ from './components/FAQ';
import Features from './components/features';
import Terms from './components/terms';
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider} from '@rainbow-me/rainbowkit';
import { WagmiProvider, createConfig } from 'wagmi';
import {
  mainnet,
  sepolia
} from 'wagmi/chains';
import { QueryClientProvider, QueryClient,} from "@tanstack/react-query";
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { injectedWallet, metaMaskWallet, ledgerWallet, coinbaseWallet, phantomWallet, trustWallet, uniswapWallet, rainbowWallet, walletConnectWallet,} from '@rainbow-me/rainbowkit/wallets';


const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, ledgerWallet, coinbaseWallet, phantomWallet, walletConnectWallet, uniswapWallet, rainbowWallet, trustWallet, injectedWallet,],
    },
  ],
  {
    appName: 'Zentih Cats',
    projectId: '4f99e25b6a3869f716146e669d83bd38',
  }
);

const config = createConfig ({
    connectors,
    appName: 'Zenith Cats',
    projectId: '4f99e25b6a3869f716146e669d83bd38',
    chains: [mainnet, sepolia],
    ssr: true,
  });


  const queryClient = new QueryClient();

const App = () => {
    const [nfts] = useGlobalState('nfts')
    useEffect(() => {
        async function fetchData() {
            await loadNfts();
        }
        fetchData();
    }, []);
    
    return (
        <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
        <div className="min-h-screen">
            <div className="gradient-bg-hero">
                <Header />
                <Routes>

                    <Route exact path="/" element={
                        <>
                            <Hero />
                            <Artworks artworks={nfts} />
                        </>
                    } />

                    <Route path="/faq" element={
                     <FAQ />
                    } />

                    <Route path="/features" element={
                        <>
                         <Features />
                         <Artworks artworks={nfts} />
                        </>
                    } />

                    <Route path="/terms" element={
                        <>
                         <Terms />
                        </>
                    } />
                    
                </Routes>
            </div> 
            <Footer />
            <Alert />
            <Loading />
        </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
    );
    }
export default App
    