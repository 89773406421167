import { ZenithCatsAbi } from './abi'
import { Contract } from 'ethers';

import { decodeError } from 'ethers-decode-error';


const ZenithCatscontractAddress = "0xDc2768F656d518F0CdfB27f06D7613C9772B847f";


const getContractInstance = async (signer) => {
    const ZenithCatsContract = new Contract(
        ZenithCatscontractAddress,
        ZenithCatsAbi,
        signer,
    );
    return ZenithCatsContract;
};


const payToMint = async (ZenithCatsContract, signer) => {


    try {
        const cost = await ZenithCatsContract.cost();
        const gasPrice = await signer.getGasPrice();

        const estimatedGasLimit = await ZenithCatsContract.estimateGas.payToMint({
            value: cost
        });

        const tx = {
            from: await signer.getAddress(), 
            to: ZenithCatscontractAddress,  
            nonce: await signer.getTransactionCount(),
            gasPrice, 
            gasLimit: estimatedGasLimit, 
            value: cost,
            data: ZenithCatsContract.interface.encodeFunctionData('payToMint'),
        };

        const sent = await signer.sendTransaction(tx);
        console.log('Transaction hash:', sent.hash);
        const receipt = await sent.wait();
        console.log('Transaction confirmed', receipt);

        return {
            success: true,
            msg: "Successfully Minted"
        }
        
    } catch (err) {
        const { error } = decodeError(err);
        return {
            success: false,
            msg: error.split(/[\[\](){}]/)[0].trim()
        }
    }
    
}

export {
    getContractInstance,
    payToMint
}

